<template>
  <div class="container">
    <h4 class="text-center mt-10" style="color:#4579DF">รายงานผู้รับผิดชอบ</h4>
    <div class="card">
      <div class="card-body px-3 py-4">
        <div class="row">
          <div class="col-4 mt-3">
            <label>เขตสุขภาพ</label>
            <b-form-select class="form-control" v-model="formsearch.areaCode" @change="setProvince($event)">
              <b-form-select-option value="" disabled
              >เลือกเขตสุขภาพ
              </b-form-select-option
              >
              <b-form-select-option
                  v-for="(item, i) in listCode"
                  :key="i"
                  :value="item"
              >เขตสุขถาพที่{{ item }}
              </b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-4 mt-3">
            <label>จังหวัด</label>
            <b-form-select class="form-control" v-model="formsearch.provinceCode" @change="setDistricts($event)">
              <b-form-select-option value="" disabled
              >เลือกจังหวัด
              </b-form-select-option
              >
              <b-form-select-option
                  v-for="(item, i) in province"
                  :key="i"
                  :value="item.code"
              >{{ item.name }}
              </b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-4 mt-3">
            <label>อำเภอ</label>
            <b-form-select class="form-control" v-model="formsearch.districtCode" @change="setHospital($event)">
              <b-form-select-option value="" disabled
              >เลือกอำเภอ
              </b-form-select-option
              >
              <b-form-select-option
                  v-for="(item, i) in district"
                  :key="i"
                  :value="item.district_id"
              >{{ item.name }}
              </b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-6 mt-2">
            <label>ประเภทของโรงพยาบาล</label>
            <b-form-select class="form-control" v-model="formsearch.hospitalTypecode">
              <b-form-select-option value="" disabled
              >เลือกประเภทของโรงพยาบาล
              </b-form-select-option
              >
              <b-form-select-option
                  v-for="(item, i) in hospitalType"
                  :key="i"
                  :value="item.value"
              >{{ item.key }}
              </b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-6 mt-2">
            <label>โรงพยาบาล</label>
            <b-form-select class="form-control" v-model="formsearch.hospType">
              <b-form-select-option value="" disabled
              >เลือกโรงพยาบาล
              </b-form-select-option
              >
              <b-form-select-option
                  v-for="(item, i) in hospcode"
                  :key="i"
                  :value="item.hospcode"
              >{{ item.name }}
              </b-form-select-option
              >
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row align-items-end">
          <div class="col">
            <button class="btn btn-next-outline" @click="showdata(1)">เเสดง</button>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary mt-5" style="margin-left:85%;" @click="excel">ส่งออก
      Excel
    </button>
    <div class="col-12 mt-15">
      <table class="Report table table-striped">
        <thead>
        <tr style="background-color: #192E58;color: #FFFFFF;">
          <th scope="col">ลำดับ</th>
          <th scope="col">เขต</th>
          <th scope="col">จังหวัด</th>
          <th scope="col">โรงพยาบาล/สสจ</th>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">ตำแหน่ง</th>
          <th scope="col">แผนก</th>
        </tr>
        </thead>
        <tbody v-if="data_user_list.length > 0">
        <tr v-for="(item,i) in data_user_list" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ item.hospital ? item.hospital.area_code : "ไม่เจอ" }}</td>
          <td>{{ item.hospital ? item.hospital.province_name : "ไม่เจอ" }}</td>
          <td>{{ item.agency_name }}</td>
          <td>{{ item.full_name }}</td>
          <td>{{ item.position }}</td>
          <td>{{ item.department }}</td>
        </tr>
        </tbody>
      </table>
      <div class="row mt-15 justify-center align-items-center">
        <div class="col-auto">1-{{ formsearch.limit }} of {{ paginateFormList.total }} items</div>
        <div class="col">
          <v-pagination
              @previous="showdata"
              @next="showdata"
              @input="showdata"
              v-model="paginateFormList.currentPage"
              :length="paginateFormList.lastPage"
          ></v-pagination>
        </div>
        <div class="col-auto">
          <select
              v-model="page"
              @change="showdata(page)"
              class="row-option py-2 px-3"
          >
            <option
                v-for="i in paginateFormList.lastPage"
                :key="i"
                :value="i"
            >
              {{ i }} / page
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ReportResponsible",
  data() {
    return {
      areaCode: "",
      hospType: "",
      listCode: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      provinceCode: "",
      districtCode: "",
      hospitalTypecode: "",
      page: "1",
      hospitalType: [
        {key: "โรงพยาบาลศูนย์_A", value: "A"},
        {key: "โรงพยาบาลทั่วไป_S", value: "S"},
        {key: "โรงพยาบาลทั่วไปขนาดเล็ก_M1", value: "M1"},
        {key: "โรงพยาบาลชุมชนแม่ข่าย_M2", value: "M2"},
        {key: "โรงพยาบาลชุมชนขนาดใหญ่_F1", value: "F1"},
        {key: "โรงพยาบาลชุมชนขนาดกลาง_F2", value: "F2"},
        {key: "โรงพยาบาลชุมชนขนาดเล็ก_F3", value: "F3"},
      ],
      formsearch: {
        areaCode: "",
        provinceCode: "",
        districtCode: "",
        hospitalTypecode: "",
        hospType: "",
        limit: 4,
      },
    }
  },
  created() {
    this.showdata(1);

  },
  methods: {
    async goToPage(event) {
      this.showLoading();
      const page = event ? event : this.paginateFormList.currentPage;
      let payload = {
        page: page,
        row: 4,
      }
      await this.$store.dispatch("datauser/getDatauser", payload);
      // await this.excel(payload);
      this.hideLoading();
    },
    excel() {
      this.$store.dispatch('datauser/getExcel', this.formsearch).then(res => {
        window.open(res)
      });
    },
    showdata(page) {
      this.formsearch.page = page
      this.$store.dispatch("datauser/getDatauser", this.formsearch);

    },
    Loading() {
      this.$loading.show({
        background: this.$swal.fire({
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        }),
        animation: true,
      });
    },
    hideLoading() {
      this.$loading.hide();
      this.$swal.hideLoading();
    },
    setProvince(event) {
      console.log(event)
      this.$store.dispatch("MasterData/setProvinceByAreaCode", event);
      this.$store.dispatch("MasterData/setHospCode", {
        province: "",
        code: this.areaCode,
      });
    },
    setDistricts(event) {
      console.log(event)
      this.$store.dispatch("MasterData/setDistrict", event);
    },
    setHospital() {
      this.$store.dispatch("MasterData/setHospCode", {
        type: this.formsearch.hospitalTypecode,
        province: this.formsearch.provinceCode,
        code: this.formsearch.areaCode,
      });
    },
  },
  computed: {
    ...mapState({
      data_user_list: (state) => {
        return state.datauser.data_user_list;
      },
      paginateFormList: (state) => {
        return state.datauser.paginateFormList;
      },
      hospcode: (state) => {
        return state.MasterData.hospcode;
      },
      province: (state) => {
        return state.MasterData.province;
      },
      district: (state) => {
        return state.MasterData.district;
      },
    }),
  }
}
</script>

<style scoped lang="scss">
.Report table {
  border-radius: 10px;

  tbody {
    display: block;
    height: 150px;

    tr {
      display: table;
      width: 100%;
    }

  }

  thead {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

}

tbody tr:nth-child(odd) {
  background: #FFFFFF !important;
}

tbody tr:nth-child(even):not(:first-child) {
  background: linear-gradient(0deg, #F9FAFF, #F9FAFF), #FFFFFF !important;
}

thead tr {
  background-color: #DEE5FB;
  color: #505B80;
}

table th:first-child {
  border-radius: 10px 0 0 0;
}

table th:last-child {
  border-radius: 0 10px 0 0;
}

</style>